import React from 'react';
export default class TransitionOverlay extends React.Component {
  render() {
	    return (
	      <div className="TransitionOverlay">
          <div className="logo">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 50 50" style={{enableBackground: "new 0 0 50 50"}}>
              <path d="M50,25c0,13.8-11.2,25-25,25S0,38.8,0,25S11.2,0,25,0S50,11.2,50,25 M40.1,27.9L40.1,27.9L30,10.3c-1-1.7-2.9-2.9-5-2.9
                s-4,1.1-5,3l0,0L9.9,28l0,0c-0.5,0.8-0.7,1.9-0.7,2.9c0,3.2,2.6,5.8,5.8,5.8h20.3c3.2,0,5.8-2.6,5.8-5.8
                C41,29.8,40.6,28.7,40.1,27.9 M25,19.2c-3.2,0-5.8,2.6-5.8,5.8s2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8S28.2,19.2,25,19.2"/>
            </svg>
           </div>
        </div>
	    )
  }
}


