import React, { Component } from "react"
import PropTypes from "prop-types"
import Header from "../components/header"
import Footer from "../components/footer"
import IntroOverlay from "../components/intro-overlay"
import TransitionOverlay from "../components/transition-overlay"
import $ from 'jquery/dist/jquery'

import ScrollIndicator from "../components/scroll-indicator"
import { withCookies, Cookies } from 'react-cookie';


class ColorWrapper extends Component {

  constructor(props) {
    //const [cookies, setCookie] = useCookies(['name']);
    super(props);
    const { cookies } = props;

    this.state = {
      class: 'ColorWrapper white intro',
      //isIntroComplete: isIntroComplete === 'true',
      isIntroComplete: false,
    }

    this.isInit = false;
    this.requesting = false;
    this.winW = 0
    this.winH = 0
    this.lastScrollY =0
    this.currentScrollY = 0
    this.currentBGColor = null
    this.currentBGColor = '#ffffff'
    this._scroll = this.scroll.bind(this);
    this._resize = this.resize.bind(this);
    this._fadeOut = this.fadeOut.bind(this);
    this._animateOut = this.animateOut.bind(this);
    this._transitionOutComplete = this.transitionOutComplete.bind(this)
    this._routeupdate = this.routeupdate.bind(this);
    this._onScroll = this.onScroll.bind(this);

    this._killRequesting = this.debounce(function () {
        this.requesting = false;
    }, 100);
  }

  initTouch(){
    $('.taphover, .button').on('touchstart', function (e) {
      var link = $(this); //preselect the link
      if (link.hasClass('hover')) {
          return true;
      } else {
          link.addClass('hover');
          $('.taphover').not(this).removeClass('hover');
          $('.button').not(this).removeClass('hover');
          return true; //extra, and to make sure the function has consistent return points
      }
    });

    $('.taphover, .button').on('touchend', function (e) {
      var link = $(this); //preselect the link
      if (link.hasClass('hover')) {
        link.removeClass('hover');
        return true;
      }
    });
    
    if(this.isTouchDevice){
      $('body').addClass('touch')
    } else {
      $('body').addClass('no-touch')
    }
  }

  is_touch_device() {
    if(window){
      var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      var mq = function(query) {
          return window.matchMedia(query).matches;
      }

      if (('ontouchstart' in window) || window.DocumentTouch) {
          return true;
      }

      // include the 'heartz' as a way to have a non matching MQ to help terminate the join
      // https://git.io/vznFH
      var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    } 

    return false;
  }

  initObjects() {
    if(window){
      this.purge();

      this.$colorWrapper = $('.ColorWrapper');
      this.isTouchDevice = this.is_touch_device();
      this.$html = $('html');

      if(this.isInit){
        this.purge();
      }
      
      this.$projectHeroImageBlock = $('.ProjectHero .image-block');
      this.isProjectHeroImageBlock = this.$projectHeroImageBlock.length > 0;

      this.$projectPreview = $('.ProjectPreview');
      this.isProjectPreview = this.$projectPreview.length > 0;

      this.$projectPreviewImageBlock = $('.ProjectPreview .image-block');
      this.isProjectPreviewImageBlock = this.$projectPreviewImageBlock.length > 0;

      this.$featuredProjectImageWrap = $('.FeaturedProject .img-wrap');
      this.isFeaturedProjectImageWrap = this.$featuredProjectImageWrap.length > 0;

      this.$textStatementWithImageBlock = $('.TextStatementWithImage .img-wrap');
      this.isTextStatementWithImageBlock = this.$textStatementWithImageBlock.length > 0;

      this.$postHeroImageWrap = $('.PostHeroImage .img-wrap');
      this.isPostHeroImageWrap = this.$postHeroImageWrap.length > 0;

      this.$projectDeviceAndQuoteImageBlock = $('.ProjectDeviceAndQuote .image-block');
      this.isProjectDeviceAndQuoteImageBlock = this.$projectDeviceAndQuoteImageBlock.length > 0;

      this.$leadership = $('.Leadership');
      this.isLeadership = this.$leadership.length > 0;

      this.$leadershipList = $('.Leadership ul li');
      this.isLeadershipList = this.$leadershipList.length > 0;

      this.$brands = $('.Brands');
      this.isBrands = this.$brands.length > 0;

      this.$brandGrid = $('.Brands .BrandGrid');
      this.isBrandGrid = this.$brandGrid.length > 0;

      this.$projectQuote = $('.ProjectQuote');
      this.isProjectQuote = this.$projectQuote.length > 0;

      this.$projectNextImageBlock = $('.ProjectNext .image-block');
      this.isProjectNextImageBlock = this.$projectNextImageBlock.length > 0;

      this.$media = $('video.autoplay-video');
      this.isMedia = this.$media.length > 0;

      //this.$keyPrinciples = $('.TextGrid.key-principles');
      //this.isKeyPrinciples = this.$keyPrinciples.length > 0;

      if(!this.isInit){
        this.$scrollIndicator = $('.ScrollIndicator');

        var that = this;
        this.$scrollIndicator.click(function(e){
          //e.preventDefault();
          if($(this).hasClass('up')){
            that.animateToTop();
          } else if($(this).hasClass('hidden')) {
            return;
          } else {
            that.animateDown();
          }
          //return;
        });

        this.isInit = true;
      }

      this.$colorKey = $('.ColorKey');
      this.$gatsby = $('#___gatsby');
      this.gatsbyObj = document.getElementById('___gatsby');
      this.resize();
    }
  }

  componentReady() {
  }

  purge(){
    try {
      if (this.$projectHeroImageBlock) {
        this.$projectHeroImageBlock.remove();
        this.$projectHeroImageBlock = null;
      }
      if (this.$projectPreview) {
        this.$projectPreview.remove();
        this.$projectPreview = null;
      }
      if (this.$projectPreviewImageBlock) {
        this.$projectPreviewImageBlock.remove();
        this.$projectPreviewImageBlock = null;
      }
      if (this.$featuredProjectImageWrap) {
        this.$featuredProjectImageWrap.remove();
        this.$featuredProjectImageWrap = null;
      }
      if (this.$textStatementWithImageBlock) {
        this.$textStatementWithImageBlock.remove();
        this.$textStatementWithImageBlock = null
      }
      if (this.$postHeroImageWrap) {
        this.$postHeroImageWrap.remove();
        this.$postHeroImageWrap =  null;
      }
      if (this.$projectDeviceAndQuoteImageBlock) {
        this.$projectDeviceAndQuoteImageBlock.remove();
        this.$projectDeviceAndQuoteImageBlock = null;
      }
      if (this.$leadership) {
        this.$leadership.remove();
        this.$leadership = null;
      }
      if (this.$leadershipList) {
        this.$leadershipList.remove();
        this.$leadershipList = null;
      }
      if (this.$brands) {
        this.$brands.remove();
        this.$brands = null;
      }
      if (this.$brandGrid) {
        this.$brandGrid.remove();
        this.$brandGrid = null;
      }
      if (this.$projectQuote) {
        this.$projectQuote.remove();
        this.$projectQuote = null;
      }
      if (this.$projectNextImageBlock) {
        this.$projectNextImageBlock.remove();
        this.$projectNextImageBlock = null;
      }
      if (this.$media) {
        this.$media.remove();
        this.$media = null;
      }
    } catch(e){
      console.log('PURGE ERROR', e);
    }
  }

  animateToTop(){
    $('html, body').animate({scrollTop : 0}, 600, 'swing', function(){
      
    });
  }

  animateDown(){
    $('html, body').animate({scrollTop : this.winH}, 600, 'swing', function(){
      
    });
  }

  animateOut(){
    this.setState({class: 'ColorWrapper white animate-out', isAnimatingOut: true})
    this.checkBGColor(true);
    let that = this;
    setTimeout(function(){
      that.transitionOutComplete();
    }, 1100);    
  }

  fadeOut(){
    this.setState({
      fade: true
    });

  }

  transitionOutComplete(){
    this.setState({
      fade: false,
      isAnimatingOut: false
    });
  }

  routeupdate() {
    if(this.isTouchDevice){
      $('body').addClass('touch')
    } else {
      $('body').addClass('no-touch')
    }

    if(this.isIntroComplete){
      this.setState({
        class: 'ColorWrapper white',
        isAnimatingOut: false
      });
    } else {
      this.setState({
        class: 'ColorWrapper white',
        isAnimatingOut: false
      })
    }
    
  
    this.setState({class: 'ColorWrapper'});
    this.initObjects();
    this.resize();


    this.$html.attr('class', 'white');
    this.$html.attr('style', '');
  }

  debounce(func, wait, immediate) {
    var timeout, args, context, timestamp, result;
    return function() {
        context = this;
        args = arguments;
        timestamp = new Date();
        var later = function() {
            var last = (new Date()) - timestamp;
            if (last < wait) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;
                if (!immediate) result = func.apply(context, args);
            }
        };
        var callNow = immediate && !timeout;
        if (!timeout) {
            timeout = setTimeout(later, wait);
        }
        if (callNow) result = func.apply(context, args);
        return result;
    };
  }

  scroll() {
    this.isReady = true;
    this.currentScrollY = window.pageYOffset;
    
    if(this.isProjectHeroImageBlock){
      var len = this.$projectHeroImageBlock.length;
      if(len > 0){
        this.checkProjectHeroParallax();  
      }
    }

    // Featured Project
    if(this.isProjectPreview){
      this.checkProjectPreviewParallax()
    }

    if(this.isFeaturedProjectImageWrap){
      this.checkFeaturedProductParallax();
    }

    // Text With Image
    if(this.isTextStatementWithImageBlock){
      this.checkTextStatementWithImageParallax();
    }

    // Text With Image
    if(this.isPostHeroImageWrap){
      this.checkPostHeroImageZoom();
    }

    if(this.isProjectDeviceAndQuoteImageBlock){
      this.checkProjectDeviceAndQuote();
    }

    if(this.isLeadershipList){
      this.checkLeadershipParallax();
    }

    if(this.isBrandGrid){
      this.checkBrandGridParallax();
    }    

    if(this.isProjectQuote){
      this.checkProjectQuoteParallax();
    }

    if(this.isProjectNextImageBlock){
      this.checkProjectNextParallax();
    }

    if(this.isMedia){
      this.checkMedia();
    }

    // if(this.isKeyPrinciples){
    //   this.checKeyPrinciplesParallax();
    // }

    this.lastScrollY = this.currentScrollY;

    this.updateHeight();
    this.checkBGColor();
    //this.checkFade();

    if (this.requesting) {
      requestAnimationFrame(this._scroll);
    }
  }

  checkBGColor(isAnimatingOut) {
    var winScrollTopWithHeight = this.currentScrollY + (this.winH*.5);
    var a;
    var b;
    var that = this;
    var clr;
    var docH = this.gatsbyObj.offsetHeight;


    
    if(this.state.isAnimatingOut || isAnimatingOut){
      this.currentBGColor = '#ffffff';
      this.$html.attr('class', 'white');
      this.$scrollIndicator.addClass('hidden');
    } else {

      this.$colorKey = $('.ColorKey');
      this.$colorKey.each(function() {
        a = $(this).offset().top;
        b = winScrollTopWithHeight;
        clr = $(this).attr('data-bgcolor');
        
        if (a < b && clr !== that.currentBGColor) {
          that.currentBGColor = clr;
        }
      });

      if (this.winH + this.currentScrollY >= docH) {
          this.$html.attr('class', 'black');
          this.currentBGColor = '#111111';
          this.$scrollIndicator.removeClass('hidden').addClass('up');
      } else {
        this.$scrollIndicator.removeClass('up');
        if(this.currentScrollY <= 10){
          this.$scrollIndicator.removeClass('hidden');
        } else {
          this.$scrollIndicator.addClass('hidden');
        }
        if(this.currentBGColor === '#ffffff'){
          this.$html.attr('class', 'white');
        } else {
          this.$html.attr('class', 'tone');
        }
      }
    }

    
    this.$gatsby.attr('style', 'background-color: '+this.currentBGColor);

    if(that.currentBGColor === '#ffffff') {
      if(!that.$projectHeroImageBlock.hasClass('fade-out')){
        that.$projectHeroImageBlock.addClass('fade-out');
      }
    } else {
      if(that.$projectHeroImageBlock.hasClass('fade-out')){
        that.$projectHeroImageBlock.removeClass('fade-out');  
      }
    }
  }

  checkProjectPreviewParallax(){
    if(!this.isTouchDevice){
      var that = this;
      if(this.winW < 1024){
        this.$projectPreviewImageBlock.each(function(){
          this.t = this.p.offset().top;
          var yoff = (that.currentScrollY-this.t-(that.winH*.5))*-.59;
          //var yoff = that.currentScrollY*.1;
          //$(this).css('transform', 'translate3d(0,'+yoff.toFixed(2)+'px,0)');
          $(this).css('transform', 'matrix(1, 0, 0, 1, 0, '+yoff.toFixed(3)+')');
        });
      } else {
        this.$projectPreviewImageBlock.each(function(){
          this.t = this.p.offset().top;
          var yoff = (((that.currentScrollY - (this.t + 270) + (that.winH*.5)) * -.19)) - 270;
          var o = $(this);
          //$(this).css('transform', 'translate3d(-50%,'+yoff.toFixed(2)+'px,0)');
          var w = o.width()*.5;
          o.css('transform', 'matrix(1, 0, 0, 1, ' + -w + ', '+yoff.toFixed(3)+')');
        });
      }
    }

  }

  checkProjectHeroParallax(){
    if(!this.isTouchDevice){
      var that = this;
      var o = this.$projectHeroImageBlock;
      var t = o.offset().top;
      var yoff = (this.currentScrollY-t-(this.winH*.5))*.59;

      var h = o.height();

      var x = (that.currentScrollY-t+that.winH)
      var y = t+h+that.winH;
      var max = 1.5;
      var min = 1.0;
      var scale = .65+((x*(max-min))/y);

      if(this.winW < 1024){
        //o.css('transform', 'translate3d(0,'+yoff.toFixed(2)+'px,0)');
        o.css('transform', 'matrix('+scale+', 0, 0, '+scale+', 0, '+yoff.toFixed(3)+')');
      } else {
        //o.css('transform', 'translate3d(-50%,'+yoff.toFixed(2)+'px,0)');
        var w = o.width()*.5;
        o.css('transform', 'matrix('+scale+', 0, 0, '+scale+', ' + -w + ', '+yoff.toFixed(3)+')');
      }
    }
  }



  checkProjectNextParallax(){
    if(!this.isTouchDevice){
      var t = this.$projectNextImageBlock.offset().top;
      var yoff = (this.currentScrollY-t-(this.winH*.5))*.59;
      if(this.winW < 768){
        this.$projectNextImageBlock.css('transform', 'matrix(1, 0, 0, 1, 0, '+yoff.toFixed(3)+')');
      } else {
        //this.$projectNextImageBlock.css('transform', 'translate3d(-50%,'+yoff.toFixed(2)+'px,0)');  
        var w = this.$projectNextImageBlock.width()*.5;
        this.$projectNextImageBlock.css('transform', 'matrix(1, 0, 0, 1, ' + -w + ', '+yoff.toFixed(3)+')');
      }
    }
  }


  checkFeaturedProductParallax(){
    var t;
    var that = this;
    var yoff
    this.$featuredProjectImageWrap.each(function(){
      t = $(this).offset().top;
      if(that.winH >= 1024){
        yoff = (that.currentScrollY-t+(that.winH*.35))*.5;
      } else {
        yoff = (that.currentScrollY-t+(that.winH*.35))*.25;
      }
      
      var scale = Math.max(1+(that.currentScrollY) / (that.winH*5), 1)
      $(this).css('transform', 'matrix(1, 0, 0, 1, 0, '+yoff.toFixed(3)+')');
      //$(this).find('img').css('transform', 'scale('+scale+')');
      $(this).find('img').css('transform', 'matrix('+scale+', 0, 0, '+scale+', 0, 0)');
    });
  }

  checkTextStatementWithImageParallax(){
    var t;
    var h;
    var that = this;
    var yoff;
    this.$textStatementWithImageBlock.each(function(){
      t = $(this).offset().top;
      h = $(this).height();
      if(that.winH >= 1024){
        yoff = (that.currentScrollY-t+(that.winH*.35))*.5;
      } else {
        yoff = (that.currentScrollY-t+(that.winH*.35))*.25;
      }
      
      var x = (that.currentScrollY-t+that.winH)
      var y = t+h+that.winH;
      var max = 1.5; 
      var min = 1;

      var scale = 1+((x*(max-min))/y);
      
      //$(this).css('transform', 'translate3d(0,'+yoff.toFixed(3)+'px,0)');
      $(this).css('transform', 'matrix(1, 0, 0, 1, 0, '+yoff.toFixed(3)+')');
      //$(this).find('img').css('transform', 'scale('+scale+')');
      $(this).find('img').css('transform', 'matrix('+scale+', 0, 0, '+scale+', 0, 0)');
    });
  }

  checkLeadershipParallax(){
    var t;
    var h;
    var that = this;
    var yoff;
    this.$leadershipList.each(function(){
      t = $(this).offset().top;
      h = $(this).height();
      
      var x = (that.currentScrollY-t+that.winH)
      var y = t+h+that.winH;
      var max = 1.75; 
      var min = 1;

      var scale = 1+((x*(max-min))/y);
      //$(this).find('img').css('transform', 'scale('+scale+')');
      $(this).find('img').css('transform', 'matrix('+scale+', 0, 0, '+scale+', 0, 0)');
    });

    this.$leadership.each(function(){
      t = $(this).offset().top;
      if(that.winH >= 1024){
        yoff = (that.currentScrollY-t+(that.winH*.35))*.5;    
      } else {
        yoff = (that.currentScrollY-t+(that.winH*.35))*.25;  
      }

      //$(this).css('transform', 'translate3d(0,'+yoff.toFixed(3)+'px,0)');
      $(this).css('transform', 'matrix(1, 0, 0, 1, 0, '+yoff.toFixed(3)+')');
    });
  }

  checkProjectQuoteParallax(){  
    var t;
    var that = this;
    var yoff;

    this.$projectQuote.each(function(){
      t = $(this).offset().top;
      if(that.winH >= 1024){
        yoff = (that.currentScrollY-t+(that.winH*.35))*.5;
      } else {
        yoff = (that.currentScrollY-t+(that.winH*.35))*.25;
      }

      //$(this).css('transform', 'translate3d(0,'+yoff.toFixed(3)+'px,0)');
      $(this).css('transform', 'matrix(1, 0, 0, 1, 0, '+yoff.toFixed(3)+')');
    });
  }

  checkBrandGridParallax(){
    var t;
    var that = this;
    var yoff;
    if(this.winW < 1024) {
      this.$brands.each(function(){
        t = $(this).offset().top;
        yoff = (that.currentScrollY-t+(that.winH*.35))*.25;
        //$(this).css('transform', 'translate3d(0,'+yoff.toFixed(3)+'px,0)');
        $(this).css('transform', 'matrix(1, 0, 0, 1, 0, '+yoff.toFixed(3)+')');
      });

      //this.$brandGrid.css('transform', 'translate3d(0,0,0)'); 
      this.$brands.css('transform', 'matrix(1,0,0,1,0,0)');
    } else {
      this.$brandGrid.each(function(){
        t = $(this).offset().top;
        yoff = (that.currentScrollY-t+(that.winH*.35))*.5;
        //$(this).css('transform', 'translate3d(0,'+yoff.toFixed(3)+'px,0)');
        $(this).css('transform', 'matrix(1, 0, 0, 1, 0, '+yoff.toFixed(3)+')');
      });  
      //this.$brands.css('transform', 'translate3d(0,0,0)');
      this.$brands.css('transform', 'matrix(1,0,0,1,0,0)');
    }
  }

  checKeyPrinciplesParallax(){
    var that = this;
    var obj = this.$keyPrinciples;
    var t = obj.offset().top;
    var yoff = (this.currentScrollY-t-(that.winH*.5))*.59;
    //obj.css('transform', 'translate3d(0,'+yoff.toFixed(3)+'px,0)');
    obj.css('transform', 'matrix(1, 0, 0, 1, 0, '+yoff.toFixed(3)+')');
  }

  checkPostHeroImageZoom(){
    var t;
    var h;
    var that = this;
    this.$postHeroImageWrap.each(function(){
      t = $(this).offset().top;
      h = $(this).height();
      
      var x = (that.currentScrollY-t+that.winH)
      var y = t+h+that.winH;
      var max = 1.3;
      var min = 1;
      var scale = 1+((x*(max-min))/y);
      //$(this).find('img').css('transform', 'scale('+scale+')');
      $(this).find('img').css('transform', 'matrix('+scale+', 0, 0, '+scale+', 0, 0)');
    });
  }

  checkProjectDeviceAndQuote(){
    var t;
    var h;
    var that = this;
    this.$projectDeviceAndQuoteImageBlock.each(function(){
      t = $(this).offset().top;
      h = $(this).height();
      
      var x = (that.currentScrollY-t+that.winH)
      var y = t+h+that.winH;
      var max = 1.3;
      var min = 1;
      var scale = 1+((x*(max-min))/y);
      //$(this).find('img').css('transform', 'scale('+scale+')');
      $(this).find('img').css('transform', 'matrix('+scale+', 0, 0, '+scale+', 0, 0)');
    });
  }


  checkFade(isFirstCheck) {
    var winScrollTopWithHeight = this.currentScrollY + this.winH;
    var a;
    var b;
    var delay = 0;
    var maxDur = 3000;

    $('.fader').not('.faded').each(function() {
      a = $(this).offset().top;
      b = winScrollTopWithHeight;
      
      if (a < b && !$(this).hasClass('faded')) {
        delay += 150;
        delay = delay > maxDur ? maxDur : delay;
        $(this).attr('style', 'transition-delay: ' + delay + 'ms !important');
        //$(this).addClass('faded');
      }
    });

  }   

  checkMedia(){
    // Get current browser top and bottom
    var tolerancePixel = 0;
    var scrollTop = this.currentScrollY + tolerancePixel;
    var scrollBottom = this.currentScrollY + this.winH - tolerancePixel;

    this.$media.each(function(index, el) {
      var yTopMedia = $(this).offset().top;
      var yBottomMedia = $(this).height() + yTopMedia;

      if(scrollTop < yBottomMedia && scrollBottom > yTopMedia){ //view explaination in `In brief` section above
          $(this).get(0).play();
      } else {
          $(this).get(0).pause();
      }
    });
}

  resize() {
    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
    this.updateHeight();
    this.setTops();
    this.onScroll();
  }

  updateHeight(){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    let scrollIndicatorRight = 28
    if(this.winW >= 768){
      Math.max(40, window.innerWidth - 1600)
    }
    document.documentElement.style.setProperty('--scrollIndicatorRight', `${scrollIndicatorRight}px`);
  }

  setTops(){
    this.$projectPreviewImageBlock.each(function(){
      this.p = $(this).parent();
    });
  }

  onScroll(){
    if (!this.requesting) {
        this.requesting = true;
        requestAnimationFrame(this._scroll);
    }
    this._killRequesting();
  }

  componentDidMount () {
    const that = this;
    const { cookies } = this.props;
    var isIntroComplete = cookies.get('intro-complete');

    if(isIntroComplete === 'true'){
      setTimeout(function(){
        that.introComplete();
      }, 250);      
      that.setState({isIntroComplete: true, class: 'ColorWrapper' });
    } else {
      setTimeout(function(){
        that.introComplete();
      }, 5000);

      setTimeout(function(){
        const { cookies } = that.props;
        cookies.set('intro-complete', 'true', { path: '/', expires: null});
        that.setState({isIntroComplete: true, class: 'ColorWrapper' });
      }, 6000);
    }
  }

  introComplete () {
    this.initStage();
    this.initObjects();
    this.initTouch();
  }

  initStage() {
    //window.addEventListener("DOMContentLoaded", this._scroll, false);
    window.addEventListener('resize', this._resize);
    window.addEventListener('transitionOut', this._fadeOut);
    window.addEventListener('transitionOutComplete', this._transitionOutComplete);
    window.addEventListener('routeupdate', this._routeupdate);
    window.addEventListener('componentReady', this._componentReady);
    window.addEventListener('animateOut', this._animateOut);
    window.addEventListener('scroll', this._onScroll, false); 
  }

  render() {
    if(this.state.isIntroComplete) {
      return (
        <div className={this.state.class} >
          <Header />
          <ScrollIndicator/>
          <main id="MainContent">{this.props.children}</main>
          <Footer/>
          <TransitionOverlay></TransitionOverlay>
          <IntroOverlay></IntroOverlay>
        </div>
      )
    } else {
      return (
        <div className={this.state.class} >
          <Header/>
          <ScrollIndicator/>
          <main id="MainContent">{this.props.children}</main>
          <Footer/>
          <IntroOverlay></IntroOverlay>
        </div>
      )
    }
    
  }
}

ColorWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withCookies(ColorWrapper);