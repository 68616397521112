import React, { Component } from "react"

class Accessibe extends Component {
	constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div dangerouslySetInnerHTML= {{ __html: `<script >
        (function(){
            var s = document.createElement('script'),
            e = ! document.body ? document.querySelector('head') : document.body;
            s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
            s.async = true;
            s.onload = function(){
                acsbJS.init({
                        statementLink     : '',
                        feedbackLink      : '',
                        footerHtml        : '',
                        hideMobile        : true,
                        hideTrigger       : true,
                        language          : 'en',
                        position          : 'right',
                        leadColor         : '#1b1a1d',
                        triggerColor      : '#1b1a1d',
                        triggerRadius     : '5px',
                        triggerPositionX  : 'right',
                        triggerPositionY  : 'bottom',
                        triggerIcon       : 'people',
                        triggerSize       : 'small',
                        triggerOffsetX    : 20,
                        triggerOffsetY    : 20,
                        mobile            : {
                            triggerSize       : 'small',
                            triggerPositionX  : 'right',
                            triggerPositionY  : 'center',
                            triggerOffsetX    : 0,
                            triggerOffsetY    : 0,
                            triggerRadius     : '5px'
                        }
                });
            };
            e.appendChild(s);
        }());
        </script>` }}></div>
    )
  }
}

export default Accessibe