/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = ({ location, prevLocation }) => {

  var event;
	if(document.createEvent){
	    event = new Event('routeupdate');
			// Dispatch the event.
			window.dispatchEvent(event);
	}
}