	import React, { Component } from "react"
import { navigateTo } from 'gatsby-link'

class Link extends Component {
	constructor(props) {
		super(props)
		this.state = {
			to: `/`,
		}
		this.handleClick = this.handleClick.bind(this)
		this.navigateTo = this.navigateTo.bind(this)
		this.getClassName = this.getClassName.bind(this)
	}

	navigateTo(to) {
		navigateTo(to)
		this.setState({ to: null })	
	}

	handleClick(e) {
		e.stopPropagation()
		e.preventDefault()

		if (typeof window !== `undefined`) {
	  	if (window.location.pathname !== this.props.to) {
				if(this.props.onClick) {
					this.props.onClick(e)
				}
				this.setState({ to: this.props.to })
				setTimeout(() => this.navigateTo(this.state.to), this.props.delay || 500)
			}
		}
	}

	getClassName(){
		if (typeof window !== `undefined`) {
			if(window.location.pathname.indexOf(this.props.to) > -1){
				return this.props.className ? this.props.className+ ' ' + this.props.activeClassName : this.props.activeClassName	
			} else {
				return this.props.className ? this.props.className : ''
			}
		}

		return this.props.className ? this.props.className : ''
	}

	render(){
		return (
			<a 
				href={this.props.to}
				className={this.getClassName()}
				style={this.props.style}
				onClick={e => this.handleClick(e)}>
				{this.props.children}
			</a>
		)
	}
}

export default Link