import React from 'react';
import $ from 'jquery/dist/jquery';
import Link  from './delayed-gatsby-link';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: 'closed',
      scrolled: false,
      underlined: false,
      rotAmt: 0,
      selectorWidth: 0,
      selectorLeft: 0,
      selectorOpacity: 0,
      pathname: '/'
    }

    //destructuring pathname from location
  

    this.isReady = false;
    this.requesting = false;
    this._transitionOut = this.transitionOut.bind(this);
    this._closeMenu = this.closeMenu.bind(this);
    //this._scrollProps = this.scrollProps.bind(this);
    this._scroll = this.scroll.bind(this);
    this._resize = this.resize.bind(this);
    this._routeupdate = this.routeupdate.bind(this);
    this._itemClicked = this.itemClicked.bind(this);
    this._toggleOpen = this.toggleOpen.bind(this);
    this._lastScrollY = 0;
    this._currentScrollY = 0;
    this._currentWindowHeight = 0;
    this._onScroll = this.onScroll.bind(this);

    this._killRequesting = this.debounce(function () {
        this.requesting = false;
    }, 100);
    
  }

  componentDidUpdate(prevProps, prevState, shouldFireRouteUpdate) {
    
  }

  toggleOpen() {
    if (this.state.menu === 'closed') {
      this.openMenu();  
    } else {
      this.closeMenu(true);
    }
  }

  openMenu(){
    this.setState({
      menu: 'open'
    });

    document.documentElement.style.overflow = 'hidden';  // firefox, chrome
    document.documentElement.style.height = '100%';
    document.body.scroll = "no"; // ie only
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    $('body').addClass('menu-open');
  }

  closeMenu(isToggled) {
    this.setState({
      menu: 'closed'
    });

    if(isToggled){
      $('body').addClass('menu-closing');
    }

    document.documentElement.style.overflow = '';  // firefox, chrome
    document.documentElement.style.height = '';
    document.body.scroll = "yes"; // ie only
    document.body.style.overflow = '';
    document.body.style.height = '';

    setTimeout(function(){
      $('body').removeClass('menu-closing');
      $('body').removeClass('menu-open');
    }, 500);
  }

  scrollProps(e) {
    this._currentScrollY = window.scrollY;
  }

  debounce(func, wait, immediate) {
    var timeout, args, context, timestamp, result;
    return function() {
        context = this;
        args = arguments;
        timestamp = new Date();
        var later = function() {
            var last = (new Date()) - timestamp;
            if (last < wait) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;
                if (!immediate) result = func.apply(context, args);
            }
        };
        var callNow = immediate && !timeout;
        if (!timeout) {
            timeout = setTimeout(later, wait);
        }
        if (callNow) result = func.apply(context, args);
        return result;
    };
  }

  scroll(e) {
    this.isReady = true;
    this._currentScrollY = window.scrollY;
    var docH = document.getElementById('___gatsby').offsetHeight;
    var s = {};

    if(this._currentScrollY <= 10){
      s = {scrolled: false, underlined: false};
    } else if ((window.innerHeight + window.scrollY) >= docH) {
      s = {scrolled: false, underlined: true};
    } else if(this._currentScrollY > this._lastScrollY){
      s = {underlined: false};
      if(!this.state.scrolled && this._currentScrollY>10){
        s = {scrolled: true};
      } else if(this.state.scrolled && this._currentScrollY<=10){
        s = {scrolled: false};
      }
    } else if (this._lastScrollY > this._currentScrollY){
      s = {scrolled: false, underlined: true};
    }

    this.setState(s);

    this._lastScrollY = this._currentScrollY;

    if (this.requesting) {
      requestAnimationFrame(this._scroll);
    }
  }

  resize() {
    var winW = window.innerWidth;
    this._currentWindowHeight = window.innerHeight;
    if(winW >= 768 && this.state.menu === 'open'){
      this.closeMenu();
    }
    this.updateSelector();
  }

  routeupdate() {
    if(this.state){
      var amt = this.state.rotAmt + 120;
      console.log('pathname',  window.location.pathname);
      this.setState({rotAmt: amt, animateOut:false, pathname: window.location.pathname});
    }

    if(!this.isReady){
      this.scroll();  
    }

    this.updateSelector();
  }

  updateSelector(target) {
    var el = target ? target : $('.Header nav li a.active');
    if(el.length > 0){
      var w = el.width() - 0;
      var l = el.parent().position().left + 120;
      this.setState({selectorWidth: w, selectorLeft: l, selectorOpacity: 1})  
    } else {
      this.setState({selectorOpacity: 0})  
    }
  }

  transitionOut(){
    this.closeMenu();
    //if($(window).scrollTop()>0){
      //$('header .holder').css('transition-delay', '0ms');  
      //$('header .holder').removeClass('faded');  
    //}
  }

  onScroll(){
    if (!this.requesting) {
        this.requesting = true;
        requestAnimationFrame(this._scroll);
    }
    this._killRequesting();
  }

  componentDidMount () {
    window.addEventListener('routeupdate', this._routeupdate);
    window.addEventListener('transitionOut', this._transitionOut);
    //window.addEventListener('scroll', this._scrollProps);
    window.addEventListener('resize', this._resize);
    window.addEventListener('scroll', this._onScroll, false); 
    this.state.pathname = window.location.pathname;
    
    if(!this.isReady){
      this.scroll();  
    }
    var that = this;
    
    setTimeout(function(){
      that.resize();
      that.routeupdate();
    }, 100);
    
  }

  itemClicked(event){
    window.dispatchEvent( new Event('animateOut') );
    this.closeMenu();
    //$('.Header nav li a').not($(event.target)).removeClass('active');
    if($(event.target).hasClass('menu-item')){
      this.updateSelector($(event.target));  
    } else {
      this.updateSelector();
    }
    
  }

  render() {
    let cname = 'Header';

    if(this.state.menu === 'open'){
      cname += ' open';
    }

    if(this.state.scrolled === true) {
      cname += ' scrolled';
    }

    if(this.state.underlined === true) {
      cname += ' underlined';
    }

    console.log('render pathname',  this.state.pathname);

    return (
      <header className={cname}>
        <div className="holder fader">
          <div className="logo">
            <Link delay={1000} to="/" className="circ" aria-label="illcreative home" style={{transform: 'rotate('+this.state.rotAmt+'deg)'}} onClick={this._itemClicked}>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="44px"
                   viewBox="0 0 50 50" style={{enableBackground: "new 0 0 50 50"}}>
                <path d="M50,25c0,13.8-11.2,25-25,25S0,38.8,0,25S11.2,0,25,0S50,11.2,50,25 M40.1,27.9L40.1,27.9L30,10.3c-1-1.7-2.9-2.9-5-2.9
                  s-4,1.1-5,3l0,0L9.9,28l0,0c-0.5,0.8-0.7,1.9-0.7,2.9c0,3.2,2.6,5.8,5.8,5.8h20.3c3.2,0,5.8-2.6,5.8-5.8
                  C41,29.8,40.6,28.7,40.1,27.9 M25,19.2c-3.2,0-5.8,2.6-5.8,5.8s2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8S28.2,19.2,25,19.2"/>
              </svg>
              <span className="visually-hidden">illcreative</span>
            </Link>
          </div>
          <nav role="navigation">
            <ul>
              <li style={{transitionDelay: '40mx' }}>
                <Link delay={1000} to="/work/" 
                  className="menu-item" 
                  getProps={() => {
                    console.log(this.state.pathname);
                    return this.state.pathname.indexOf('/work') > -1 ? { className: "active" } : null
                  }} 
                  activeClassName="active"
                  partiallyActive={true}
                  onClick={this._itemClicked}>
                  Work
                </Link>
              </li>
              <li style={{transitionDelay: '0ms' }}>
                <Link delay={1000} to="/about/" className="menu-item" activeClassName="active" 
                onClick={this._itemClicked}>About</Link>
              </li>
              <li style={{transitionDelay: '80ms' }}>
                <Link delay={1000} to="/services/" className="menu-item" activeClassName="active" 
                onClick={this._itemClicked}>Services</Link>
              </li>
              <li style={{transitionDelay: '120ms' }}>
                <Link delay={1000} to="/ecommerce/" className="menu-item" activeClassName="active" 
                  onClick={this._itemClicked}>Ecommerce
                </Link>
                </li>
              <li style={{transitionDelay: '160ms' }}>
                <Link delay={1000} to="/blog" 
                  className="menu-item" 
                  activeClassName="active" 
                  partiallyActive={true}
                  getProps={() => {
                    return this.state.pathname.indexOf('/blog/') > -1 ? { className: "active" } : null
                  }} 
                  onClick={this._itemClicked}>
                  Thoughts
                </Link>
              </li>
            </ul>
            <div className="selector" style={{left: this.state.selectorLeft+'px', width: this.state.selectorWidth+'px', opacity: this.state.selectorOpacity}}></div>
            <div className="contact-holder" style={{transitionDelay: '200ms' }}>
              <Link delay={1000} to="/contact/" activeClassName="active" className="button" 
                onClick={this._itemClicked}>Contact Us
              </Link>
            </div>
          </nav>

          <button className="hamburger" onClick={this._toggleOpen} aria-label="Menu">
            <span className="line one"></span>
            <span className="line two"></span>
            <span className="line three"></span>
            <span className="line four"></span>
          </button>
        </div>
      </header>
    )
  }
}