import React from 'react';
import $ from 'jquery/dist/jquery'

export default class ScrollIndicator extends React.Component {
	constructor(props) {
    //const [cookies, setCookie] = useCookies(['name']);
    super(props);
    

    this.state = {
      class: 'ScrollIndicator fader'
    }

    this._transitionOutComplete = this.transitionOutComplete.bind(this)
    this._routeupdate = this.routeupdate.bind(this);
  }

	routeupdate(e){
		if(window){
			if(window.location.pathname === '/work/'){
				this.setState({
	      	class: 'ScrollIndicator white'
	    	});
			} else {
				this.setState({
	      	class: 'ScrollIndicator'
	    	});
			}
		}
	}

	transitionOutComplete(){

	}

	componentDidMount () {
		
		//this.routeupdate();
		//this.routeupdate();
    if(window){
    	window.addEventListener('routeupdate', this._routeupdate);
    }
	}

  render() {
    return (
      <span className={this.state.class}>
        <svg className="down" version="1.1" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg"
					 x="0px" y="0px" viewBox="0 0 9 34">
				<polygon fill="#111111" points="5.5,29 5.5,0 3.5,0 3.5,29 0,29 4.5,33.5 9,29 "/>
				</svg>

				<svg className="up" version="1.1" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg"
					 x="0px" y="0px" viewBox="0 0 9 34">
				<polygon fill="#111111" points="5.5,29 5.5,0 3.5,0 3.5,29 0,29 4.5,33.5 9,29 "/>
				</svg>
      </span>
    )
  }
}