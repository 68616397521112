/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import ColorWrapper from "../components/color-wrapper"
import Accessibe from "../components/accessibe"
import "../styles/theme.scss"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>illcreative</title>
        <meta name="description" content="HELLO, WORLD. We’re illcreative, we make websites + apps for emerging brands."/>
        <meta name="robots" content="index, follow" data-react-helmet="true"/>
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" data-react-helmet="true"/>
        <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" data-react-helmet="true"/>
        <meta property="og:locale" content="en_US" data-react-helmet="true"/>
        <meta property="og:type" content="article" data-react-helmet="true"/>
        <meta property="og:title" content="illcreative" data-react-helmet="true"/>
        <meta property="og:description" content="HELLO, WORLD. We’re illcreative, we make websites + apps for emerging brands." data-react-helmet="true"/>
        <meta property="og:url" content="https://www.illcreative.com" data-react-helmet="true"/>
        <meta property="og:site_name" content="illcreative" data-react-helmet="true"/>
        <meta property="article:modified_time" content="2021-12-07T17:27:20+00:00" data-react-helmet="true"/>
        <meta property="og:image" content="https://cdn.illcreative.com/anatomy-light-gray-1.jpg" data-react-helmet="true"/>
        <meta property="og:image:width" content="2000" data-react-helmet="true"/>
        <meta property="og:image:height" content="2000" data-react-helmet="true"/>
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
      </Helmet>
      <a className="in-page-link visually-hidden skip-link" href="#MainContent">Skip to content</a>
      <ColorWrapper children={children} data={data}></ColorWrapper>
      <Accessibe/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout