import React, { Component } from "react"
import Link  from './delayed-gatsby-link';

class Footer extends Component {
	constructor(props) {
    super(props);
    this.state = {
      fade: false,
      statement: 0
    }

    this._fadeOut = this.fadeOut.bind(this);
    this._itemClicked = this.itemClicked.bind(this);
    this._accessibilityClicked = this.accessibilityClicked.bind(this);
    this._transitionOutComplete = this.transitionOutComplete.bind(this);
    this._statement = this.statement.bind(this);
    this._routeupdate = this.routeupdate.bind(this);

  }

  itemClicked(event){
    window.dispatchEvent( new Event('animateOut') );
  }

  routeupdate() {
    var obj = {statement: this.state.statement === 0 ? 1 : 0}
    this.setState(obj)
  }

  fadeOut(){
    //$('footer').css("transition-delay", "0ms");
    this.setState({
      fade: true
    });
  }

  transitionOutComplete(){
    this.setState({
      fade: false
    });
    //$('footer.fader').removeClass('faded');
  }

  statement(){
    
    if(this.state.statement === 1) {
      return (
        <div className="content">
          <h3 className="eyebrow">Talk is Cheap</h3>
          <h2 className="eyebrow-before">Let’s get to <span className="highlight">work</span>.</h2>
          <a href="mailto:biz@illcreative.com" className="button large white" onClick={this.contactClicked.bind(this)}>New Business</a>
        </div>
       )  
    } else {
      return (
        <div className="content">
          <h3 className="eyebrow">Get in Touch</h3>
          <h2 className="eyebrow-before">Let’s work <span className="highlight">together</span>.</h2>
          <a href="mailto:biz@illcreative.com" className="button large white" onClick={this.contactClicked.bind(this)}>New Business</a>
        </div>
       )
    }
  }

  componentDidMount () {
    window.addEventListener('routeupdate', this._routeupdate);
    window.addEventListener('transitionOut', this._fadeOut);
    window.addEventListener('transitionOutComplete', this._transitionOutComplete);
  }

  animateToTop(){
    window.dispatchEvent( new Event('animateToTop') );
  }

  contactClicked(event){
    if(window.location.pathname.indexOf('contact') > -1){
      event.stopPropagation();
      event.preventDefault();
      this.animateToTop();
      return false;
    }
  }

  accessibilityClicked(event){
    alert('accessibilityClicked');
  }

  render() {
    return (
      <footer className="Footer" >
        <div className={this.state.fade ? 'footer-wrap fader fade' : 'footer-wrap fader'}>
          {this._statement()}
        </div>
        <div className="bottom">
          <div className="inner clearfix">
            <ul className="legal">
              <li><button className="link-accessibility" data-acsb="trigger" data-acsb-custom-trigger="true">Accessibility</button></li>
              <li><Link delay={1000} to="/terms" onClick={this._itemClicked}>Terms of Use</Link></li>
              <li><Link delay={1000} to="/privacy" onClick={this._itemClicked}>Privacy Policy</Link></li>
            </ul>
            <small>© 2011–{new Date().getFullYear()} Illuminati Creative, LLC. All Rights Reserved.</small>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer